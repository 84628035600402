<template>
    <div class="root">
        <v-row class="mt-10">
            <v-col cols="2">
            </v-col>
            <v-col cols="8" align="center">
                <div v-if="userList.length>0">
                    Outbound, Waiting Response
                </div>
                <v-card elevation="0" outlined v-for="(u, index) in userList" :index="index" :key="index" class="my-2">
                    <v-card-text class=justify-center>
                        <v-row>
                            <div class="col col-6">
                                <h2> <a :href="'/' + u.target_channel_name">{{u.target_channel_name}}</a> </h2>
                            </div>
                            <div class="col col-6">
                                <a @click="cancel(u.target_user_id)"> cancel </a>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="2" />
        </v-row>
    </div>  
</template>
<script>
import api from '/src/utils/api';

const FriendOutboundRequests = {
    name: 'FriendOutboundRequests',
    components: {
    },
    data() {
        return {
            userList: [],
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            // console.log("FriendOutboundRequests refresh");
            api.getCall('get-outbound-friend-requests').then(userList => {
                this.userList = userList;
            });
        },
        cancel(friendUserId) {
            api.postAuthRequired('cancel-friend-request', {friendUserId}).then(() => {
                this.refreshAllFriendData();
            });
        },
    },
    props: ['refreshAllFriendData'],
};

export default FriendOutboundRequests;
</script>
